<template>

  <div class="dark-theme">
    <div class="timers">
      <timerControl
        v-for="task in ActiveTasks"
        :key="task[`name`]"
        :name="task[`name`]"
        :duration="task[`duration`]"
        @stop_entity="stopCurrentTask"
      />
    </div>
    <chart2/>
    <div class="counter_block" style="width: -webkit-fill-available">
      <input v-model="name" placeholder="Task Name" required />
      <button @click="addTask" class="btn button">
        start
      </button>
    </div>
    <div class="popular_names">
      <ul class="tasks">
        <li class="task_el" v-for="name in popularNames" :key="name" @click="setTaskName(name)">
          {{ name }}
        </li>
      </ul>
    </div>

    <div v-for="(tasks, date) in sortedTasksByDate" :key="date" class="day">
      <h2>{{ date }}</h2>
      <ul>
        <li
          v-for="item in tasks"
          :key="item.task_name"
          class="entity"
          style="padding: 0px 20px"
        >
          <p>{{ item.name }}</p>
          <p style="color: #ffffff7d">
            {{ item.start_time }} - {{ item.end_time || "Активна" }}
          </p>
          <p>{{ item.duration }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, computed } from "vue";
import timerControl from "./timerControl.vue";
import api from "../services/api";
import chart2 from "./chart2.vue";

// Refs for handling task creation and state
const name = ref("");
const isRunning = ref(false);
const data = ref([]);
const ActiveTasks = ref([]);
let timer = null;

async function addTask() {
  isRunning.value = true;
  try {
    await api.post("/time_entity", {
      name: name.value,
    });
    name.value = ""; // Clear the input field
    await fetchData(); // Refresh data after adding a task
  } catch (error) {
    console.error("Error adding task:", error);
  }
}

async function stopTask() {
  isRunning.value = false;
  try {
    await api.post("/time_entity/complete");
    await fetchData(); // Refresh data after stopping the task
  } catch (error) {
    console.error("Error stopping task:", error);
  }
}
async function stopCurrentTask(name) {
  ActiveTasks.value.forEach((item) => {
    if(name == item.name){
      ActiveTasks.value.pop(item);
    }
  })
  stopTask()
}

function setTaskName(taskName) {
  name.value = taskName;
}
// Function to fetch data from the API
async function fetchData() {
  try {
    const response = await api.get("/time_entities");
    data.value = response.data;
    response.data.forEach((item) => {
      if (!item.end_timestamp) {
        ActiveTasks.value.push({"name":item.name,"start_timestamp":item.start_timestamp,"duration": calculateActiveDuration(item["start_timestamp"])});
      }
    });
    if (!timer) {
      startTimer(); // Ensure only one timer is running
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}

const popularNames = computed(() => {
  const nameFrequency = {};
  data.value.forEach((item) => {
    if (item.name) {
      nameFrequency[item.name] = (nameFrequency[item.name] || 0) + 1;
    }
  });

  return Object.entries(nameFrequency)
    .sort((a, b) => b[1] - a[1])
    .slice(0, 5)
    .map(([name]) => name);
});

// Function to convert Unix timestamp to time
function convertUnixToTime(unixTimestamp) {
  if (!unixTimestamp) return "";
  const date = new Date(unixTimestamp * 1000);
  return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
}

// Function to calculate duration between two timestamps
function calculateDuration(startTimestamp, endTimestamp) {
  const duration = endTimestamp - startTimestamp;
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = duration % 60;
  const paddedHours = String(hours).padStart(2, "0");
  const paddedMinutes = String(minutes).padStart(2, "0");
  const paddedSeconds = String(seconds).padStart(2, "0");
  return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
}

// Function to calculate the active duration of a task
function calculateActiveDuration(startTimestamp) {
  const now = Math.floor(Date.now() / 1000);
  return calculateDuration(startTimestamp, now);
}

// Function to format a timestamp to a readable date
function formatDate(timestamp) {
  const date = new Date(timestamp * 1000);
  return date.toDateString();
}

// Function to start a timer that updates the data every second
function startTimer() {
  timer = setInterval(() => {
    ActiveTasks.value = ActiveTasks.value.map((item) => {
      return {
        ...item,
        duration: calculateActiveDuration(item["start_timestamp"])
      };
    });
    data.value = data.value.map((item) => ({ ...item }));
  }, 1000);
}

// Computed property to sort tasks by date
const sortedTasksByDate = computed(() => {
  const sortedData = [...data.value].reverse();
  const tasksByDate = {};

  sortedData.forEach((item) => {
    const startDate = item.start_timestamp
      ? formatDate(item.start_timestamp)
      : "Неизвестно";

    if (!tasksByDate[startDate]) {
      tasksByDate[startDate] = [];
    }

    tasksByDate[startDate].push({
      ...item,
      start_time: convertUnixToTime(item.start_timestamp),
      end_time: item.end_timestamp
        ? convertUnixToTime(item.end_timestamp)
        : null,
      duration: item.end_timestamp
        ? calculateDuration(item.start_timestamp, item.end_timestamp)
        : calculateActiveDuration(item.start_timestamp),
    });
  });
  console.log(tasksByDate)
  return tasksByDate;
});

// Lifecycle hooks
onMounted(() => {
  fetchData();
});

onBeforeUnmount(() => {
  clearInterval(timer); // Ensure the timer is cleared when the component is destroyed
});
</script>


<style scoped>
.timers{
  width: -webkit-fill-available;
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  flex-direction: column;
}
.counter_block{
  display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.task_el{
  padding: 5px 10px;
}
.clock {
  text-align: center;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 150px;
}
.day {
  width: -webkit-fill-available;
}
.entity {
  display: flex;
  gap: 15px;
  justify-content: space-between;
  border-radius: 15px;
}

.popular_names {
  width: -webkit-fill-available;
}
.tasks {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  cursor: pointer;
  font-size: 10px;
}
.dark-theme {
  background-color: rgb(18, 18, 18);
  color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  /* max-width: 600px; */
  margin: auto;
  font-family: Arial, sans-serif;
}

h1,
h2 {
  color: #e0e0e0;
  border-bottom: 1px solid rgb(51, 51, 51);
  padding-bottom: 10px;
}

input {
  background-color: #333;
  color: #ffffff;
  border: 1px solid #444;
  border-radius: 4px;
  padding: 10px;
  margin-right: 10px;
  width: -webkit-fill-available;
}

button {
  background-color: #10ea00;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 10px 15px;
  cursor: pointer;
}

button:hover {
  background-color: #0fb300;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

li {
  background-color: #333;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 4px;
}
.flex {
  display: flex;
}
</style>