import axios from 'axios';

// Функция для получения значения куки по имени
function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

// Создаем экземпляр Axios с настройками
const api = axios.create({
  baseURL: 'https://mxln.ru/api',
  withCredentials: true, // Включаем отправку куки на сервер
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `${getCookie("auth_hash")}`, // Используем значение куки в заголовке Authorization
  },
});


export default api;
