<template>
    <div class="timeline">
      <div
        v-for="(value, hour) in timelineData"
        :key="hour"
        class="timeline-segment"
        :style="segmentStyle(value)"
        :title="`Hour ${hour}: ${value}`"
      >
        {{ value }}
      </div>
    </div>
  </template>
  
  <script>
  import api from "@/services/api";

  export default {
    data() {
      return {
        timelineData: {}  // Изначально данные пустые
      };
    },
    async created() {
      await this.fetchData();
    },
    methods: {
      async fetchData() {
        try {
          const response = await api.get("/dashboard_today");
        const jsonData = response.data;
          const entries = Object.entries(jsonData);
  
          // Перемещение элемента с ключом "0" в конец
          const indexZero = entries.findIndex(([key]) => key === "0");
          if (indexZero !== -1) {
            const zeroEntry = entries.splice(indexZero, 1)[0];
            entries.push(zeroEntry);
          }
  
          // Преобразуем обратно в объект для использования в компоненте
          this.timelineData = Object.fromEntries(entries);
        } catch (error) {
          console.error("Ошибка при загрузке данных:", error);
        }
      },
      segmentStyle(value) {
        return {
          width: '4%', // Например, если всего 24 сегмента (часов)
          height: '10px', // Можно настроить высоту по желанию
          backgroundColor: this.getColor(value),
          margin: '2px'
        };
      },
      getColor(value) {
    // Пример диапазона значений от 0 до 100
    const maxValue = 60;
    const minValue = 0;

    // Вычисляем относительное значение от 0 до 1
    const normalizedValue = (value - minValue) / (maxValue - minValue);

    // Настройка градиента от темного синего к яркому синему
    // Можно настроить цвета по вашему усмотрению
    const startColor = [51, 51, 51];  // Темно-синий для низких значений
    const endColor = [0, 255, 0];   // Яркий синий для высоких значений

    // Интерполяция цвета
    const r = Math.round(startColor[0] + normalizedValue * (endColor[0] - startColor[0]));
    const g = Math.round(startColor[1] + normalizedValue * (endColor[1] - startColor[1]));
    const b = Math.round(startColor[2] + normalizedValue * (endColor[2] - startColor[2]));

    return `rgb(${r}, ${g}, ${b})`;
}
    }
  };
  </script>
  
  <style scoped>
  .timeline {
    display: flex;
    align-items: center;
  }
  .timeline-segment {
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(255, 255, 255, 0.245);
    font-size: 8px;
    font-weight: bold;
    border-radius: 4px;
  }
  </style>
  