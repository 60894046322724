<template>
  <div class="column">
    <h1>Set Auth</h1>
    <div class="manual_block">
      <input v-model=hashTyped type="text">
      <button @click="setAuthManually">set manually</button>
    </div>
  </div>
</template>
<style>
.column{
  display: flex;
    flex-direction: column;
}
</style>
<script setup>
import { ref } from 'vue';
import api from '../services/api';
const hashTyped = ref("")
const setAuthManually = async () => {
  setCookie('auth_hash', hashTyped.value, 9999);
  api.defaults.headers['Authorization'] = hashTyped.value;
  alert('Auth hash установлен в куку');
};

const setCookie = (name, value, days) => {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};
</script>
